import { defineStore } from 'pinia'

export const useSpinnerStore = defineStore('spinner-store', () => {
  const show = ref(false);

  const showSpinner = () => {
    show.value = true;
  };

  const hideSpinner = () => {
    setTimeout(function(){
        show.value = false;
    },500);
   
  };

  
  return { show, showSpinner, hideSpinner };
  
});
  