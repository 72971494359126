import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class FinancingModule extends FetchFactory<T> {
  private RESOURCE = '/financiacion-cuota';




    /**
   * Calcula una cuota
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async show (
      params, 
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  

      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json'
            },
            params: {
              ...params
            }
          };
          return this.call(
            'GET',
            `${this.RESOURCE}/${params?.id_vehiculos}?meses=${params?.meses}&entrada=${params?.entrada}&tipo=${params?.tipo}`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }

  

}


export default FinancingModule;