// [FILE]: repository/modules/news.ts

// 3rd's
import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class RelatedModule extends FetchFactory<T> {
  private RESOURCE = '/relacionados';


  /**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async index (
    url: String,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}${url}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }


}

export default RelatedModule;