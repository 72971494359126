// [File]: plugins/api.ts

// 3rd's
import { $fetch, FetchOptions } from 'ofetch';

// locals
import FormsModule from '~/repository/modules/forms';
import NewsModule from '~/repository/modules/news';
import ConcesionariosModule from '~/repository/modules/concesionarios';
import VehiculosVoModule from '~/repository/modules/vehiculosvo';
import VehiculosVnModule from '~/repository/modules/vehiculosvn';
import VehiculosElModule from '~/repository/modules/vehiculosel';
import JobsModule from '~/repository/modules/jobs';
import CmsModule from '~/repository/modules/cms';
import MetaDatosModule from '~/repository/modules/metadatos';
import BrandsModule from '~/repository/modules/brands';
import BloqueSeoModule from '~/repository/modules/bloque-seo';
import PromosModule from '~/repository/modules/promos';
import RelatedModule from '~/repository/modules/related';
import UserModule from '~/repository/modules/user';
import FavouritesModule from '~/repository/modules/favourites';
import ComparatorModule from '~/repository/modules/comparator';
import GamasModule from '~/repository/modules/gamas';
import SearchesModule from '~/repository/modules/searches';
import BookingsModule from '~/repository/modules/bookings';
import PopupsModule from '~/repository/modules/popups';
import MotosVnModule from '~/repository/modules/motosvn';
import MotosVoModule from '~/repository/modules/motosvo';
import FinancingModule from '~/repository/modules/financing';


interface IApiInstance {
  forms: FormsModule;
  news: NewsModule;
  concesionarios: ConcesionariosModule;
  vehiculosVo:VehiculosVoModule;
  vehiculosVn:VehiculosVnModule;
  vehiculosEl:VehiculosElModule;
  jobs: JobsModule;
  cms: CmsModule;
  metadatos: MetaDatosModule;
  brands: BrandsModule;
  bloqueSeo: BloqueSeoModule;
  promos: PromosModule;
  gamas: GamasModule;
  related: RelatedModule;
  user: UserModule;
  favourites: FavouritesModule;
  comparator: ComparatorModule;
  searches: SearchesModule;
  bookings: BookingsModule;
  popups: PopupsModule;
  motosVn: MotosVnModule,
  motosVo: MotosVoModule,
  financing: FinancingModule,
}

export default defineNuxtPlugin(() => {

  const config = useRuntimeConfig();
  const fetchOptions: FetchOptions = {
    baseURL: config.public.apiBaseUrl
  };

  // Create a new instance of $fecther with custom option
  const apiFecther = $fetch.create(fetchOptions);

  // An object containing all repositories we need to expose
  const modules: IApiInstance = {
    forms: new FormsModule(apiFecther),
    news: new NewsModule(apiFecther),
    concesionarios: new ConcesionariosModule(apiFecther),
    vehiculosVo: new VehiculosVoModule(apiFecther),
    vehiculosVn: new VehiculosVnModule(apiFecther),
    vehiculosEl: new VehiculosElModule(apiFecther),
    jobs: new JobsModule(apiFecther),
    cms: new CmsModule(apiFecther),
    metadatos: new MetaDatosModule(apiFecther),
    brands: new BrandsModule(apiFecther),
    bloqueSeo: new BloqueSeoModule(apiFecther),
    promos: new PromosModule(apiFecther),
    gamas: new GamasModule(apiFecther),
    related: new RelatedModule(apiFecther),
    user: new UserModule(apiFecther),
    favourites: new FavouritesModule(apiFecther),
    comparator: new ComparatorModule(apiFecther),
    searches: new SearchesModule(apiFecther),
    bookings: new BookingsModule(apiFecther),
    popups: new PopupsModule(apiFecther),
    motosVn: new MotosVnModule(apiFecther),
    motosVo: new MotosVoModule(apiFecther),
    financing: new FinancingModule(apiFecther)

  };

  return {
    provide: {
      api: modules
    }
  };
});