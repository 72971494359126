
<script lang="ts">
export default {
  name: 'error'
};
</script>

<script setup lang="ts">
const router = useRouter();

const props = defineProps({
  error: Object
});

const handleErrorLeft =() => {
  if(props.error.statusCode===404){
    clearError({ redirect: '/coches-nuevos/' });
  }
  else {
    clearError();
    router.back();
  }
};
const handleErrorRight =()=>{
  if(props.error.statusCode===404){
    clearError({ redirect: '/coches-electricos/' });
  }
  else {
    clearError({ redirect: '/' });
  }
};

const errorTypeContent=computed(()=>{
  if(props.error.statusCode === 404){
    return (['error404.png', 'Página desaparecida','No encontramos la página que buscas','Al parecer esta sección no se encuentra disponible en estos momentos, sin embargo no ha sido un viaje en vano, te ofrecemos otras alternativas']);
  }else{
    return (['error.png', 'Algo salió mal','Al parecer nos ha surgido un error ajeno a nosotros']);
  }
});


</script>

<template lang="pug">
NuxtLayout
  .app-error
    .container.my-5.text-center.py-5
      img(
        :src="`/images/${errorTypeContent[0]}`"
        alt="")
      p.text1.fw-bold.mb-1 {{errorTypeContent[1]}}
      p.text2(
        :class="error.statusCode === 404 ? 'mb-1' : 'mb-5'"
      ) {{ errorTypeContent[2]}}
      p.text3.mb-5(v-if="error.statusCode===404") {{ errorTypeContent[3]}}
      .buttons.justify-content-center.d-flex.flex-row.col-lg-7.mx-auto.gap-3.flex-wrap
        .col-10.col-lg-5 
          button.btn.btn--tertiary.btn--large.text-center(
            @click="handleErrorLeft"  
          ) {{error.statusCode===404 ? 'Ver stock vehículos nuevos':'Volver a lo que estaba haciendo'}}
        .col-10.col-lg-5
          button.btn.btn--primary.btn--large.text-center(
            @click="handleErrorRight"  
          ) {{error.statusCode===404 ? 'Ver vehículos eléctricos':'Volver a la home'}}
      
  .container.d-none
    p(v-for="(item, index) in error" :key="index") {{ error[index] }}

</template>

<style scoped lang="scss">
  .app-error{
    img {
      width: 164px;
      height: auto;
    }
    .text1{
      font-size: 52px;
      font-weight: 700;
    }

    .text2{
      font-size: 28px;
      font-weight: 400;
      color:var(--secondary-60);
    }

    .text3{
      font-size: 14px;
      font-weight: 300;
    }
}

</style>
