import { ref } from 'vue';
import { defineStore } from 'pinia';

export const usePopupsStore = defineStore('popups-store', () => {

  const { $api } = useNuxtApp();
  const popups=ref([]);
  const popupPagesVisited=ref([]);

  const show=reactive({
    status:false,
    type: 'home',
    brand: '',
    url: ''
  });

  const getPopupsInfo = async () => {
    const { data, error } = await $api.popups.index({
      pick:['payload']
  });
    if (error.value) {
        throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
    }else{
      popups.value=data.value.payload; 
    }

  };

  const getTimeOut = (brand='')=>{
    const arr=popups.value.filter(obj => obj.marca === (brand === '' ? false : brand))[0];
    const timeout=(arr && arr['timeout']['timeout'] ) ? parseInt(arr['timeout']['timeout']) : 30000;
    return timeout;
  };

  const showPopup = (brand='',type:'home'|'timeout'='home') =>{
    const arr=popups.value.filter(obj => obj.marca === (brand === '' ? false : brand))[0];
    const url=arr ? (arr[type === 'home' ? 'inicio' : 'timeout'] ?? ''):'';
    
    if(!show.status && url && url!==''){
      if(type==='home'){
        addPageVisited(brand==='' ? 'main' : brand);
      }
      show.brand=brand;
      show.type=type;
      show.status=true;
      show.url= url;
    }

  };

  const closePopup = () =>{
    if(show.status){
      formatShow();
    }
  };

  const formatShow = () =>{
    show.status=false;
    show.type= 'home';
    show.brand= '';
    show.url= '';
  };

  const addPageVisited=(brand) =>{
    if(!popupPagesVisited.value.includes(brand)){
      popupPagesVisited.value.push(brand);
    }
  };

  return { 
    popups,
    show,
    popupPagesVisited,
    showPopup,
    closePopup,
    getTimeOut,
    getPopupsInfo,
    addPageVisited
  };
},{
  persist:{
    storage: persistedState.sessionStorage,
    paths: ['popupPagesVisited'],
  }
});