import { default as indexpmPiN2xdGQMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/aviso-legal/index.vue?macro=true";
import { default as indexa8iXLJl2W9Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93wfSVP5T11jMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue?macro=true";
import { default as indexGysdeV9BE9Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93syOWwt4YOkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexaISRd9ckOaMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as _91detail_933sMzteYm32Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue?macro=true";
import { default as indexA0BPqFTCnzMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/index.vue?macro=true";
import { default as indexs7AoiVxa4iMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/empresas/index.vue?macro=true";
import { default as indexwQpPW2t9CgMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/favoritos/index.vue?macro=true";
import { default as indexyMw9cuw8pSMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue?macro=true";
import { default as index0BrYkj8oVuMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/index.vue?macro=true";
import { default as indexBFGItAzJAWMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gracias/index.vue?macro=true";
import { default as indexBOZQfvix3oMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/index.vue?macro=true";
import { default as indexy4QTqldeVkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/instalaciones/index.vue?macro=true";
import { default as _91detail_93J1YBvBljR4Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue?macro=true";
import { default as indexZLx4ZLM9EkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/index.vue?macro=true";
import { default as _91detail_93KARUSqcgfkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue?macro=true";
import { default as indexiu1dGQcdx6Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/index.vue?macro=true";
import { default as _91id_93Q7gzDU0Fa3Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/[id].vue?macro=true";
import { default as indexxvIiETAqEeMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/index.vue?macro=true";
import { default as indexbVVuEmi5o3Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/busquedas/index.vue?macro=true";
import { default as indexoHoyXq4VYMMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/comparador/index.vue?macro=true";
import { default as indexYcDjZrE290Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/favoritos/index.vue?macro=true";
import { default as indexU3SsIiWIqgMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/index.vue?macro=true";
import { default as index58dGeihFq1Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/perfil/index.vue?macro=true";
import { default as indexv9deguuD5TMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/reservas/index.vue?macro=true";
import { default as indexueWDUx7ctKMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue?macro=true";
import { default as indexnrkPhaLLh0Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue?macro=true";
import { default as _91id_93z603Ob3SqIMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue?macro=true";
import { default as index9WdsEWpE1uMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/index.vue?macro=true";
import { default as _91id_93yAJ9ynxeVMMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue?macro=true";
import { default as indexairMoo737eMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93zN0aGbTVRbMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue?macro=true";
import { default as indexZJRQmORz3qMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/error/index.vue?macro=true";
import { default as _91key2_93da9DE0iwtlMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93bU6KFasF50Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexrzoi5YBWyzMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/seguros/index.vue?macro=true";
import { default as indexbXnWp9OfcTMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/sostenibilidad/index.vue?macro=true";
import { default as indexUO2QeYkDPDMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/te-escuchamos/index.vue?macro=true";
import { default as _91marca_93MUHGPwEEoBMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca].vue?macro=true";
import { default as indexNTRzQdRhEWMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/alianzas/index.vue?macro=true";
import { default as index60JMsRcIysMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/aviso-legal/index.vue?macro=true";
import { default as indexvueaYXi300Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/canal-denuncias/index.vue?macro=true";
import { default as indexK9CXPR6w1YMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue?macro=true";
import { default as indexcEtVNlnkoZMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/index.vue?macro=true";
import { default as indexFPqztnt7W2Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93wVQOPZIirNMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/[detail].vue?macro=true";
import { default as indexus2etQFi2WMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/index.vue?macro=true";
import { default as _91detail_93RXjAaOcGXjMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/[detail].vue?macro=true";
import { default as indexlfUVwsdJEBMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93PSIkxpITw1Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexE4ojzABTC9Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as indexRTPfn5UlioMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/gracias/index.vue?macro=true";
import { default as indexUKG13trhCXMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/historia/index.vue?macro=true";
import { default as indexAf9LH5I1eNMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/index.vue?macro=true";
import { default as indexEBeKptuKRlMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/[id]/index.vue?macro=true";
import { default as indextItr6tpJEPMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/index.vue?macro=true";
import { default as indexmNg8PpVLXsMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/movilidad/index.vue?macro=true";
import { default as _91id_93rIxNk86qHZMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/[id].vue?macro=true";
import { default as indexYttrQ6ZQlFMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/index.vue?macro=true";
import { default as _91id_939OmwtcfhOcMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/[id].vue?macro=true";
import { default as indexSOM9Ve9kM8Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/index.vue?macro=true";
import { default as indexrrzaVqsq7GMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/busquedas/index.vue?macro=true";
import { default as indexnQjxUwgfYjMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/comparador/index.vue?macro=true";
import { default as indexIu0RiDJLHhMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/favoritos/index.vue?macro=true";
import { default as indexWsSrtQp0DrMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/index.vue?macro=true";
import { default as indexU7uFTEbBZLMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/perfil/index.vue?macro=true";
import { default as indexKcV3WmNdYGMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/reservas/index.vue?macro=true";
import { default as indexyukGnTpWniMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-cookies/index.vue?macro=true";
import { default as indexAvd78ZjCHjMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-privacidad/index.vue?macro=true";
import { default as index1g2qFGuRrhMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-marcas/index.vue?macro=true";
import { default as indexcUd9JP8kK1Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93Uh6o6Z32klMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/[type]/[slug].vue?macro=true";
import { default as indexPp6YUxOazWMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/error/index.vue?macro=true";
import { default as _91key2_93tYzLSMnUyqMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93uT1sMaWYzgMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexBX6VN3YSglMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/seguros/index.vue?macro=true";
import { default as indexDricjyo0RbMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/servicios-empresas/index.vue?macro=true";
import { default as indexmEVuTnzjnZMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/solicitarfactura/index.vue?macro=true";
import { default as indexdh9VTwtOrlMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/sostenibilidad/index.vue?macro=true";
import { default as indexKsryKL4Q57Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/te-escuchamos/index.vue?macro=true";
import { default as _91id_93Losj9pMCQBMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/[id].vue?macro=true";
import { default as indexl9cUVwUXHtMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/index.vue?macro=true";
import { default as indexjOPVYRNMTUMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue?macro=true";
export default [
  {
    name: _91marca_93MUHGPwEEoBMeta?.name,
    path: "/:marca()",
    meta: _91marca_93MUHGPwEEoBMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca].vue"),
    children: [
  {
    name: "marca-aviso-legal",
    path: "aviso-legal",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/aviso-legal/index.vue")
  },
  {
    name: "marca-cita-previa-taller",
    path: "cita-previa-taller",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue")
  },
  {
    name: "marca-coches-nuevos-detail",
    path: "coches-nuevos/:detail()",
    meta: _91detail_93wfSVP5T11jMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue")
  },
  {
    name: "marca-coches-nuevos",
    path: "coches-nuevos",
    meta: indexGysdeV9BE9Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/index.vue")
  },
  {
    name: "marca-coches-nuevos-kilometro-cero-detail",
    path: "coches-nuevos/kilometro-cero/:detail()",
    meta: _91detail_93syOWwt4YOkMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue")
  },
  {
    name: "marca-coches-nuevos-kilometro-cero",
    path: "coches-nuevos/kilometro-cero",
    meta: indexaISRd9ckOaMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue")
  },
  {
    name: "marca-coches-ocasion-detail",
    path: "coches-ocasion/:detail()",
    meta: _91detail_933sMzteYm32Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue")
  },
  {
    name: "marca-coches-ocasion",
    path: "coches-ocasion",
    meta: indexA0BPqFTCnzMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/index.vue")
  },
  {
    name: "marca-empresas",
    path: "empresas",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/empresas/index.vue")
  },
  {
    name: "marca-favoritos",
    path: "favoritos",
    meta: indexwQpPW2t9CgMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/favoritos/index.vue")
  },
  {
    name: "marca-gama-detalle",
    path: "gama/:detalle()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue")
  },
  {
    name: "marca-gama",
    path: "gama",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/index.vue")
  },
  {
    name: "marca-gracias",
    path: "gracias",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gracias/index.vue")
  },
  {
    name: "marca",
    path: "",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/index.vue")
  },
  {
    name: "marca-instalaciones",
    path: "instalaciones",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/instalaciones/index.vue")
  },
  {
    name: "marca-motos-nuevas-detail",
    path: "motos-nuevas/:detail()",
    meta: _91detail_93J1YBvBljR4Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue")
  },
  {
    name: "marca-motos-nuevas",
    path: "motos-nuevas",
    meta: indexZLx4ZLM9EkMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/index.vue")
  },
  {
    name: "marca-motos-ocasion-detail",
    path: "motos-ocasion/:detail()",
    meta: _91detail_93KARUSqcgfkMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue")
  },
  {
    name: "marca-motos-ocasion",
    path: "motos-ocasion",
    meta: indexiu1dGQcdx6Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/index.vue")
  },
  {
    name: "marca-noticias-id",
    path: "noticias/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/[id].vue")
  },
  {
    name: "marca-noticias",
    path: "noticias",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/index.vue")
  },
  {
    name: "marca-panel-busquedas",
    path: "panel/busquedas",
    meta: indexbVVuEmi5o3Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/busquedas/index.vue")
  },
  {
    name: "marca-panel-comparador",
    path: "panel/comparador",
    meta: indexoHoyXq4VYMMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/comparador/index.vue")
  },
  {
    name: "marca-panel-favoritos",
    path: "panel/favoritos",
    meta: indexYcDjZrE290Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/favoritos/index.vue")
  },
  {
    name: "marca-panel",
    path: "panel",
    meta: indexU3SsIiWIqgMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/index.vue")
  },
  {
    name: "marca-panel-perfil",
    path: "panel/perfil",
    meta: index58dGeihFq1Meta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/perfil/index.vue")
  },
  {
    name: "marca-panel-reservas",
    path: "panel/reservas",
    meta: indexv9deguuD5TMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/reservas/index.vue")
  },
  {
    name: "marca-politica-de-cookies",
    path: "politica-de-cookies",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue")
  },
  {
    name: "marca-politica-de-privacidad",
    path: "politica-de-privacidad",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue")
  },
  {
    name: "marca-promociones-marcas-id",
    path: "promociones-marcas/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue")
  },
  {
    name: "marca-promociones-marcas",
    path: "promociones-marcas",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/index.vue")
  },
  {
    name: "marca-promociones-posventa-id",
    path: "promociones-posventa/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue")
  },
  {
    name: "marca-promociones-posventa",
    path: "promociones-posventa",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/index.vue")
  },
  {
    name: "marca-reservar-type-slug",
    path: "reservar/:type()/:slug()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue")
  },
  {
    name: "marca-reservar-error",
    path: "reservar/error",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/error/index.vue")
  },
  {
    name: "marca-reservar-estado-key1-key2",
    path: "reservar/estado/:key1()/:key2()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue")
  },
  {
    name: "marca-reservar-estado-imprimir-key1-key2",
    path: "reservar/estado/imprimir/:key1()/:key2()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue")
  },
  {
    name: "marca-seguros",
    path: "seguros",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/seguros/index.vue")
  },
  {
    name: "marca-sostenibilidad",
    path: "sostenibilidad",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/sostenibilidad/index.vue")
  },
  {
    name: "marca-te-escuchamos",
    path: "te-escuchamos",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/te-escuchamos/index.vue")
  }
]
  },
  {
    name: "alianzas",
    path: "/alianzas",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/alianzas/index.vue")
  },
  {
    name: "aviso-legal",
    path: "/aviso-legal",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/aviso-legal/index.vue")
  },
  {
    name: "canal-denuncias",
    path: "/canal-denuncias",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/canal-denuncias/index.vue")
  },
  {
    name: "centros-chapa-y-pintura-id",
    path: "/centros-chapa-y-pintura/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue")
  },
  {
    name: "centros-chapa-y-pintura",
    path: "/centros-chapa-y-pintura",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/index.vue")
  },
  {
    name: "cita-previa-taller",
    path: "/cita-previa-taller",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/cita-previa-taller/index.vue")
  },
  {
    name: "coches-electricos-detail",
    path: "/coches-electricos/:detail()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/[detail].vue")
  },
  {
    name: "coches-electricos",
    path: "/coches-electricos",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/index.vue")
  },
  {
    name: "coches-nuevos-detail",
    path: "/coches-nuevos/:detail()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/[detail].vue")
  },
  {
    name: "coches-nuevos",
    path: "/coches-nuevos",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/index.vue")
  },
  {
    name: "coches-nuevos-kilometro-cero-detail",
    path: "/coches-nuevos/kilometro-cero/:detail()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue")
  },
  {
    name: "coches-nuevos-kilometro-cero",
    path: "/coches-nuevos/kilometro-cero",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue")
  },
  {
    name: "gracias",
    path: "/gracias",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/gracias/index.vue")
  },
  {
    name: "historia",
    path: "/historia",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/historia/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/index.vue")
  },
  {
    name: "instalaciones-id",
    path: "/instalaciones/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/[id]/index.vue")
  },
  {
    name: "instalaciones",
    path: "/instalaciones",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/index.vue")
  },
  {
    name: "movilidad",
    path: "/movilidad",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/movilidad/index.vue")
  },
  {
    name: "noticias-electricos-id",
    path: "/noticias-electricos/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/[id].vue")
  },
  {
    name: "noticias-electricos",
    path: "/noticias-electricos",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/index.vue")
  },
  {
    name: "noticias-id",
    path: "/noticias/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/[id].vue")
  },
  {
    name: "noticias",
    path: "/noticias",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/index.vue")
  },
  {
    name: "panel-busquedas",
    path: "/panel/busquedas",
    meta: indexrrzaVqsq7GMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/busquedas/index.vue")
  },
  {
    name: "panel-comparador",
    path: "/panel/comparador",
    meta: indexnQjxUwgfYjMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/comparador/index.vue")
  },
  {
    name: "panel-favoritos",
    path: "/panel/favoritos",
    meta: indexIu0RiDJLHhMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/favoritos/index.vue")
  },
  {
    name: "panel",
    path: "/panel",
    meta: indexWsSrtQp0DrMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/index.vue")
  },
  {
    name: "panel-perfil",
    path: "/panel/perfil",
    meta: indexU7uFTEbBZLMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/perfil/index.vue")
  },
  {
    name: "panel-reservas",
    path: "/panel/reservas",
    meta: indexKcV3WmNdYGMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/reservas/index.vue")
  },
  {
    name: "politica-de-cookies",
    path: "/politica-de-cookies",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-cookies/index.vue")
  },
  {
    name: "politica-de-privacidad",
    path: "/politica-de-privacidad",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-privacidad/index.vue")
  },
  {
    name: "promociones-marcas",
    path: "/promociones-marcas",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-marcas/index.vue")
  },
  {
    name: "promociones-posventa",
    path: "/promociones-posventa",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-posventa/index.vue")
  },
  {
    name: "reservar-type-slug",
    path: "/reservar/:type()/:slug()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/[type]/[slug].vue")
  },
  {
    name: "reservar-error",
    path: "/reservar/error",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/error/index.vue")
  },
  {
    name: "reservar-estado-key1-key2",
    path: "/reservar/estado/:key1()/:key2()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue")
  },
  {
    name: "reservar-estado-imprimir-key1-key2",
    path: "/reservar/estado/imprimir/:key1()/:key2()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue")
  },
  {
    name: "seguros",
    path: "/seguros",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/seguros/index.vue")
  },
  {
    name: "servicios-empresas",
    path: "/servicios-empresas",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/servicios-empresas/index.vue")
  },
  {
    name: "solicitarfactura",
    path: "/solicitarfactura",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/solicitarfactura/index.vue")
  },
  {
    name: "sostenibilidad",
    path: "/sostenibilidad",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/sostenibilidad/index.vue")
  },
  {
    name: "te-escuchamos",
    path: "/te-escuchamos",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/te-escuchamos/index.vue")
  },
  {
    name: "trabaja-con-nosotros-id",
    path: "/trabaja-con-nosotros/:id()",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/[id].vue")
  },
  {
    name: "trabaja-con-nosotros",
    path: "/trabaja-con-nosotros",
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/index.vue")
  },
  {
    name: "user-recovery-password-token1-token2",
    path: "/user/recovery-password/:token1()/:token2()",
    meta: indexjOPVYRNMTUMeta || {},
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue")
  }
]