<script setup>
    import { onMounted} from 'vue';
    import { useBrandsInfoStore } from '~/store/brandsInfo';


    onMounted(() => {


        const route = useRoute();

        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
        externalScript.setAttribute('data-cbid', '63eff5da-9e88-44a7-8bfe-d14f14e22d61')
        externalScript.async = true;
        document.head.appendChild(externalScript);

        if(route.params?.marca){
            const brandsInfoStore = useBrandsInfoStore();
            const brand = brandsInfoStore.getBrand(route.params.marca);

            if(!!brand.css){

            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = brand.css;
            link.media = 'all';
            document.head.appendChild(link);
            }

        }

        watch(
            () => route.params.marca,
            () => {
                if(route.params?.marca){
                    const brandsInfoStore = useBrandsInfoStore();
                    const brand = brandsInfoStore.getBrand(route.params.marca);

                    if(!!brand.css){
                        var link = document.createElement('link');
                        link.rel = 'stylesheet';
                        link.type = 'text/css';
                        link.href = brand.css;
                        link.media = 'all';
                        document.head.appendChild(link);
                    }

                }
            }
        );

    });



</script>


<template lang="pug">
NuxtPage 
</template>

<style lang="scss"></style>
