/*
import { defineStore } from 'pinia'

export const useSearchsStore = defineStore('searchs-store', () => {
  const searchs = ref([]);

  const addSearch = (payload) => {
    searchs.value.push(payload);
  };
  
  return { addSearch, searchs };
  
});
  
*/

import {ref,watch,onMounted,computed} from 'vue';
import { defineStore } from 'pinia';
import { useSpinnerStore } from '@/store/spinner';



export const useSearchesStore = defineStore('searches-store', ()=>{

    const { $api } = useNuxtApp();
    const spinnerStore = useSpinnerStore();

    const { isAuthenticated} = useSanctumAuth();

    const searchesExtended=ref([]);
    const searchesBasic=ref([]);
    const info=ref([]);


    const noSearches = computed(()=> searchesBasic.value.length===0);
    
    type seccion = 'vn' | 'vo' | 've';


    const add= async (json,hashMd5,seccion: seccion,marca: string,isSearchesPage: boolean)=>{
        if(!isSearchesPage && !exist(hashMd5)){
            const formData = new FormData();
            formData.append('filters',json);
            formData.append('filters_md5',hashMd5);
            formData.append('seccion',seccion);
            formData.append('marca',marca);
          
            spinnerStore.showSpinner();
            const { data,status, error } = await $api.searches.store(formData,{
                pick:['payload']
            });
            spinnerStore.hideSpinner();
          
            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }
          
            await getBasic();
            return true;
        }

    };
    

    function exist(hashMd5: string){
        return searchesBasic.value.includes(hashMd5) ? true : false;
    };
    
    const remove= async (code: string, isSearchesPage: boolean)=>{
            const formData = new FormData();
            spinnerStore.showSpinner();
            const { data,status, error } = await $api.searches.remove(code,formData,{
                pick:['payload']
            });
            spinnerStore.hideSpinner();
            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            await getBasic();

            if(isSearchesPage){
                await getExtended();
            }

            return true;
    };




    /**
     * 
     * busquedas version básica 
     */
    const getBasic= async ()=>{
        if(isAuthenticated.value){
            const formData = new FormData();
            let attempt= false;

            formData.set('list_type','basic');
            spinnerStore.showSpinner();

            const { data,status,refresh, error } = await $api.searches.index(formData,{
                pick:['payload'],    
            });
            
            
            if (((data.value === null) || ( !Array.isArray(data.value.payload) && typeof(data.value.payload)===Object )) && !attempt ) {
                attempt=true;
                await refresh();
            }


            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }


            searchesBasic.value=data.value.payload;
            spinnerStore.hideSpinner();
            return searchesBasic.value;
        }else{
            searchesExtended.value=[];
            searchesExtended.value=[];
            info.value=[];
        }
    };


     /**
     * 
     * traes la versión extendida
     */
     const getExtended= async ()=>{
        if(isAuthenticated.value){
            const formData = new FormData();
            let attempt= false;

            formData.set('list_type','extended');
            
            spinnerStore.showSpinner();

            const { data,status,refresh, error } = await $api.searches.index(formData,{
                pick:['payload'],
            });
            
            
            if (((data.value === null) || ( Array.isArray(data.value.payload))) && !attempt ) {
                attempt=true;
                await refresh();
            }


            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            searchesExtended.value=data.value.payload.data;
            info.value=data.value.payload.metas;
            spinnerStore.hideSpinner();
            return searchesExtended.value;
        }else{
            searchesExtended.value=[];
            searchesBasic.value=[];
            info.value=[];
        }
    };

        

    watch(isAuthenticated, async ()=> {
        if (isAuthenticated.value) {
            await getBasic();
        }else{
            searchesExtended.value=[];
            searchesBasic.value=[];
            info.value=[];
        }
    });

    
    return {
        searchesBasic,
        searchesExtended,
        info,
        noSearches,
        add,
        remove,
        getBasic,
        getExtended,
        exist
    };
});