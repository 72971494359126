import { defineStore } from 'pinia'


export const useMetaDatosStore = defineStore('metadatos-store', () => {


    const metadatos = ref([]);
    const { $api } = useNuxtApp();

    const get = async () => {

  
        const {data,error} = await $api.metadatos.index({
            pick: ['payload']
        });

        if (error.value) {
   
            throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
        }

        metadatos.value = data.value.payload;
    };

    return { metadatos, get };

});
  