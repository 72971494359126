import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';

class ComparatorModule extends FetchFactory<T> {
  private RESOURCE = '/comparador';

  /**
   * * For registration of compared
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async store (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json',
          },
        };

        return this.call(
          'POST',
          `${this.RESOURCE}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }
  
    /**
   * For the return of the compared vehicles
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async index (
      formData: FormData,
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json',
            },
          };
          return this.call(
            'GET',
            `${this.RESOURCE}?${new URLSearchParams(formData).toString()}`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }

    
  /**
   * To delete compared
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async remove (
    urlParam: string,
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json',
          },
        };
        return this.call(
          'DELETE',
          `${this.RESOURCE}/${urlParam}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

    /**
   * To delete all compared
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async removeAll (
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json',
            },
          };
          return this.call(
            'DELETE',
            `${this.RESOURCE}`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }
  
}
  
export default ComparatorModule;