import revive_payload_client_4sVQNw7RlN from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_mYqpUg2ioW from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import nuxt_plugin_6wEQMY3tee from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/api.ts";
import clickOutside_ZB3Eg0wOVk from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/clickOutside.ts";
import gsap_client_YCLjR5XmPz from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/gsap.client.ts";
import init_pinia_brands_info_59P33aJ55x from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/init-pinia-brands-info.ts";
import init_pinia_comp_client_N4DlWQhPVN from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/init-pinia-comp.client.ts";
import init_pinia_fav_client_vHr1bbpiUg from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/init-pinia-fav.client.ts";
import init_pinia_popups_kr5VEWXEDE from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/init-pinia-popups.ts";
import init_pinia_searches_client_Uty46Kox5U from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/init-pinia-searches.client.ts";
import init_pinia_jLG15hhIeH from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/init-pinia.ts";
import useBootstrap_client_ouDJvcVteA from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/useBootstrap.client.ts";
import vue_dragscroll_gxCFtVqzJM from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/vue-dragscroll.ts";
import vue_splide_client_d5fFikskn8 from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/plugins/vue-splide.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_mYqpUg2ioW,
  plugin_client_LcKgStRyi6,
  nuxt_plugin_6wEQMY3tee,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  api_GFfDXud5Cr,
  clickOutside_ZB3Eg0wOVk,
  gsap_client_YCLjR5XmPz,
  init_pinia_brands_info_59P33aJ55x,
  init_pinia_comp_client_N4DlWQhPVN,
  init_pinia_fav_client_vHr1bbpiUg,
  init_pinia_popups_kr5VEWXEDE,
  init_pinia_searches_client_Uty46Kox5U,
  init_pinia_jLG15hhIeH,
  useBootstrap_client_ouDJvcVteA,
  vue_dragscroll_gxCFtVqzJM,
  vue_splide_client_d5fFikskn8
]