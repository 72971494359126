import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class ConcesionariosModule extends FetchFactory<T> {
  private RESOURCE = '/concesionarios';

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async index (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async marcas (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}-marcas?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async show (
    id: Number,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${id}}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }
}

export default ConcesionariosModule;