import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class FormsModule extends FetchFactory<T> {
  private RESOURCE = '/forms';
  private RESOURCE_FILES = '/forms-files';


  /**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async store (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'POST',
          `${this.RESOURCE}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

/**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
async storeWithFiles (
  formData: FormData,
  asyncDataOptions?: AsyncDataOptions<'json'>
) {

  return useAsyncData(
    () => {
      const fetchOptions:FetchOptions<'json'> = {
        headers: {
          'Accept': 'application/json'
        },
      };
      return this.call(
        'POST',
        `${this.RESOURCE_FILES}`,
        formData, // body
        fetchOptions
      );
    },
    asyncDataOptions
  ); 
}  
}

export default FormsModule;