import {ref,watch,onMounted,computed} from 'vue';
import { defineStore } from 'pinia';
import { useSpinnerStore } from '@/store/spinner';



export const useFavouritesStore = defineStore('favourites-store', ()=>{

    const { $api } = useNuxtApp();
    const spinnerStore = useSpinnerStore();

    const { isAuthenticated} = useSanctumAuth();

    const favouritesExtended=ref([]);
    const favouritesBasic=ref([]);
    const info=ref([]);


    const noFavourites = computed(()=> favouritesBasic.value.length===0);


    
    type seccion = 'vn' | 'vo' | 've';


    const add= async (id: number,seccion: seccion,marca: string,isFavouritePage: boolean)=>{
        if(!isFavouritePage && !exist(id)){
            const formData = new FormData();
            formData.append('id_vehiculos',id);
            formData.append('seccion',seccion);
            formData.append('marca',marca);
            spinnerStore.showSpinner();

            const { data,status, error } = await $api.favourites.store(formData,{
                pick:['payload']
            });
            spinnerStore.hideSpinner();

            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            await getBasic();
            return true;
        }

    };
    

    function exist(id: number){
        return favouritesBasic.value.includes(id) ? true : false;
    };
    
    const remove= async (id: number,seccion: seccion,marca: string,isFavouritePage: boolean)=>{
        if(exist(id)){
            const formData = new FormData();
            formData.append('id_vehiculos',id);
            formData.append('seccion',seccion);
            formData.append('marca',marca);
            spinnerStore.showSpinner();
            const { data,status, error } = await $api.favourites.remove(id.toString(),formData,{
                pick:['payload']
            });
            spinnerStore.hideSpinner();
            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            await getBasic();

            if(isFavouritePage){
                await getExtended();
            }

            return true;
        }
    };


    const removeAll= async ()=>{
        if(!noFavourites.value){

            const { data,status, error } = await $api.favourites.removeAll({
                pick:['payload']
            });

            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            favouritesExtended.value=[];
            favouritesBasic.value=[];
            info.value=[];

            return true;
        }

        return false;
    };


    /**
     * 
     * favoritos version básica 
     */
    const getBasic= async ()=>{
        if(isAuthenticated.value){
            const formData = new FormData();
            let attempt= false;

            formData.set('list_type','basic');
            spinnerStore.showSpinner();

            const { data,status,refresh, error } = await $api.favourites.index(formData,{
                pick:['payload']
            });
            
            if (((data.value === null) || ( !Array.isArray(data.value.payload) && typeof(data.value.payload)===Object )) && !attempt ) {
                attempt=true;
                await refresh();
            }


            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }


            favouritesBasic.value=data.value.payload;
            spinnerStore.hideSpinner();
            return favouritesBasic.value;
        }else{
            favouritesExtended.value=[];
            favouritesBasic.value=[];
            info.value=[];
        }
    };


     /**
     * 
     * traes la versión extendida
     */
     const getExtended= async ()=>{
        if(isAuthenticated.value){
            const formData = new FormData();
            let attempt= false;

            formData.set('list_type','extended');
            
            spinnerStore.showSpinner();

            const { data,status,refresh, error } = await $api.favourites.index(formData,{
                pick:['payload'],
            });
            
            
            if (((data.value === null) || ( Array.isArray(data.value.payload))) && !attempt ) {
                attempt=true;
                await refresh();
            }


            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            favouritesExtended.value=data.value.payload.data;
            info.value=data.value.payload.metas;
            spinnerStore.hideSpinner();
            return favouritesExtended.value;
        }else{
            favouritesExtended.value=[];
            favouritesBasic.value=[];
            info.value=[];
        }
    };

        

    watch(isAuthenticated, async ()=> {
        if (isAuthenticated.value) {
            await getBasic();
        }else{
            favouritesExtended.value=[];
            favouritesBasic.value=[];
            info.value=[];
        }
    });

    
    return {
        favouritesBasic,
        favouritesExtended,
        info,
        noFavourites,
        add,
        remove,
        removeAll,
        getBasic,
        getExtended,
        exist
    };
});