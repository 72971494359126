// [FILE]: repository/modules/news.ts

// 3rd's
import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class NewsModule extends FetchFactory<T> {
  private RESOURCE = '/noticias';
  private RESOURCE_BRANDS = '/marca-noticias';



  /**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async getNews (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * Return news 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async getNew (
    urlParam: string,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${urlParam}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  async getBrands (
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}-marcas`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  //Eléctricos
  async getNewsElectrics (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}?electricos=1&${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  async getBrandsElectrics (
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE+'-marcas?electricos=1'}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  //Para marcas

  /**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async getBrandNews (
    formData: FormData,
    brand: string,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE_BRANDS}/${brand}?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * Return news 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async getBrandNew (
    urlParam: string,
    brand: string,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE_BRANDS}/${brand}/${urlParam}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }  


}

export default NewsModule;