import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class UserModule extends FetchFactory<T> {
  private RESOURCE = '/cliente';
  private RESOURCE_RECOVERY = '/auth/cliente/recovery-password';
  private RESOURCE_VERIFY = '/auth/cliente/verify-token';
  private RESOURCE_UPDATE = '/auth/cliente/update-password';
  private RESOURCE_CSRF = '/sanctum/csrf-cookie';




  /**
   * For registration of users
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async store (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'POST',
          `${this.RESOURCE}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }
  
    /**
   * For update user info
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async update (
      formData: FormData,
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json' 
            },
          };
          return this.call(
            'POST',
            `${this.RESOURCE}/${formData.get('id')}`,
            formData, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }


  /**
   * For password recovery
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async recoveryPassword (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'POST',
          `${this.RESOURCE_RECOVERY}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

    /**
   * For token verification
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async verifyPassword (
      formData: FormData,
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json'
            },
          };
          return this.call(
            'GET',
            `${this.RESOURCE_VERIFY}/${formData.get('token1')}/${formData.get('token2')}/`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }

    
  /**
   * For password update
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async updatePassword (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'POST',
          `${this.RESOURCE_UPDATE}/${formData.get('token1')}/${formData.get('token2')}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * For set CSRF token 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
      async csrf (
        asyncDataOptions?: AsyncDataOptions<'json'>
      ) {
    
        return useAsyncData(
          () => {
            const fetchOptions:FetchOptions<'json'> = {
              headers: {
                'Accept': 'application/json'
              },
            };
            return this.call(
              'GET',
              `${this.RESOURCE_CSRF}`,
              undefined, // body
              fetchOptions
            );
          },
          asyncDataOptions
        ); 
      }
  
}

export default UserModule;