import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class VehiculosVoModule extends FetchFactory<T> {
  private RESOURCE = '/vehiculos-vo';


  /**
   * Obtiene todos los filtros de vo
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async filters (
    params,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
          params: {
            ...params
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}-filtros${params?.marca ? `?marca=${params.marca}` :''}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }


    /**
   * Obtiene todos los suggests
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async suggests (
      params,
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json'
            },
            params: {
              ...params
            }
          };
          return this.call(
            'GET',
            `${this.RESOURCE}-suggests?${new URLSearchParams(params).toString()}`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }

  /**
   * Obtiene todos los filtros de km0
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async filtersKm0 (
    params,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
          params: {
            ...params
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}-filtros?km0=1${params?.marca ? `&marca=${params.marca}` :''}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }


   /**
   * Obtiene todos los filtros de vo
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
   async index (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

   /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
   async singleCar (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {
    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${formData.get('id')}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async carByBrand (
    brand,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {
    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
          params: {
            marca: brand
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  

}


export default VehiculosVoModule;