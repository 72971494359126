// [FILE]: repository/modules/news.ts

// 3rd's
import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class JobsModule extends FetchFactory<T> {
  private RESOURCE = '/ofertas-empleo';


  /**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async getJobs (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * Return the products as array 
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async getJob (
    urlParam: string,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${urlParam}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

}

export default JobsModule;