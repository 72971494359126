// [FILE]: repository/factory.ts

// 3rd's
import { $Fetch, FetchOptions } from 'ofetch';

/*
 The FetchFactory acts as a wrapper around an HTTP client. 
 It encapsulates the functionality for making API requests asynchronously 
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory<T> {

  private $fetch: $Fetch;

  constructor (fetcher: $Fetch) {
    this.$fetch = fetcher;
  }


  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns 
   */
  async call (
    method: string,
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<'json'>
  ): Promise<T> {



    const xsrfToken = useCookie('XSRF-TOKEN');

    const ip = useState('ip');
  
      const headers = {
      'Accept': 'application/json',
      'X-ip-front': ip.value ? ip.value : null,
      };

      if (xsrfToken && xsrfToken.value !== null) {
        headers['X-XSRF-TOKEN'] = xsrfToken.value;
      }

      const tloUtms = useCookie('tlo_utms');
      if(tloUtms && tloUtms.value != ''){
        headers['x-mo-utms'] = new URLSearchParams(tloUtms.value).toString();
      }
   


    return this.$fetch<T>(
      url, 
      { 
        method, 
        body: data,
        headers,
        credentials: 'include',
      }
    );
  }
}

export default FetchFactory;