import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useBrandsInfoStore = defineStore('brand-info-store', () => {
  const { $api } = useNuxtApp();
  const brands = ref([]);
  const cyclesBrands=ref([]);
  const activeBrand = ref();
  const route = useRoute();

  const setBrands= async ()=>{
    const { data, error } = await $api.brands.index({
      pick:['payload']
    });

    if (error.value) {
      throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
    }
      
      brands.value= data.value.payload;
  };

  const setCyclesBrands= async ()=>{

    const { data, error } = await $api.gamas.getCyclesBrands({
      pick:['payload']
    });

    if (error.value) {
      throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
    }
      
    cyclesBrands.value= data.value.payload;
  };

  const getBrandIdBySlug = (slug) => {
    const brand = brands.value.find(brand => brand.slug === slug);

    if (brand) {
      return brand.id;
    }

    return null;
  };

  const getBrandNameBySlug = (slug) => {
    const brand = brands.value.find(brand => brand.slug === slug);

    if (brand) {
      return brand.nombre_comercial != null ? brand.nombre_comercial : brand.nombre;
    }

    return null;
  };

  const getBrandNoComercialNameBySlug = (slug) => {
    const brand = brands.value.find(brand => brand.slug === slug);

    if (brand) {
      return brand.nombre;
    }

    return null;
  };

  const getBrandSlugByNoComercialName = (name) => {
    const brand = brands.value.find(brand => brand.nombre === name);

    if (brand) {
      return brand.slug;
    }

    return null;
  };

  const isActiveBrand = (slug) => {
    const brand = brands.value.find(brand => brand.slug === slug && brand.activa === 1);

    return brand ? true : false;
  };

  const getBrand = (slug) => {
    return brands.value.find(brand => brand.slug === slug);
  };

  const getActiveBrands = () =>{
    return brands.value.filter(brand => brand.activa === 1);
  };

  const getActiveBrandsOrdered = () =>{
    const orderedBrands=getActiveBrands();
    orderedBrands.sort((a, b) => {
      if (a.orden === undefined && b.orden === undefined) {
          return 0;
      }
      if (a.orden === undefined) {
          return 1;
      }
      if (b.orden === undefined) {
          return -1;
      }
      return a.orden - b.orden;
    });

    return orderedBrands;
  };

  // Function to select specific keys and create a new object
  function selectKeys(object, ...selectedKeys) {
    const newObject = {};
    selectedKeys.forEach(key => {
      if (object.hasOwnProperty(key)) {
        newObject[key] = object[key];
      }
    });
    return newObject;
  }

  const getBrandCustomisation= async (slug)=>{


      if(slug !== activeBrand.value?.slug){

      const { data, error } = await $api.brands.show(slug,{
        pick:['payload']
      });


      if (error.value) {
        throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
      }

      activeBrand.value = data.value.payload;
      return data.value.payload;
    }
    else{
      return activeBrand;
    }
    
  };

  const getComercialActiveNames = () =>{
    return brands.value.filter(brand => brand.activa === 1).map(brand => brand.nombre_comercial != null ? brand.nombre_comercial : brand.nombre);
  };

  return { 
    activeBrand,
    brands,
    cyclesBrands,
    getBrand,
    getBrandIdBySlug,
    getBrandNameBySlug,
    getBrandNoComercialNameBySlug,
    getBrandSlugByNoComercialName,
    setBrands,
    setCyclesBrands,
    isActiveBrand,
    getActiveBrands,
    getActiveBrandsOrdered,
    getBrandCustomisation,
    getComercialActiveNames
  };
});