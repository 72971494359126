import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';

class BookingsModule extends FetchFactory<T> {
  private RESOURCE = '/reserva';
  private RESOURCE_2 = '/reservas';

  /**
   * * To check availability
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async check (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json',
          },
        };

        return this.call(
          'GET',
          `${this.RESOURCE}/${formData.get('type')}/${formData.get('id')}/disponibilidad`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }
  
    /**
   * To make a booking
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async store (
      formData: FormData,
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json',
            },
          };

          return this.call(
            'POST',
            `${this.RESOURCE}`,
            formData, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }

    
  /**
   * To get bookings
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async index (
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json',
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE_2}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

    /**
   * To get single booking
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async show (
      formData: FormData,
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json',
            },
          };
          return this.call(
            'GET',
            `${this.RESOURCE}/${formData.get('key1')}/${formData.get('key2')}/`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }
  
}

export default BookingsModule;