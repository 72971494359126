import {ref,watch,onMounted,computed} from 'vue';
import { defineStore } from 'pinia';
import { useSpinnerStore } from '@/store/spinner';



export const useComparatorStore = defineStore('comparator-store', ()=>{

    const { $api } = useNuxtApp();
    const spinnerStore = useSpinnerStore();

    const { isAuthenticated} = useSanctumAuth();

    const comparedExtended=ref([]);
    const comparedBasic=ref([]);
    const info=ref([]);


    const noCompared = computed(()=> comparedBasic.value.length===0);


    
    type seccion = 'vn' | 'vo' | 've';


    const add= async (id: number,seccion: seccion,marca: string,isComparatorPage: boolean)=>{
        if(!isComparatorPage && !exist(id)){
            const formData = new FormData();
            formData.append('id_vehiculos',id);
            formData.append('seccion',seccion);
            formData.append('marca',marca);
            spinnerStore.showSpinner();

            const { data,status, error } = await $api.comparator.store(formData,{
                pick:['payload']
            });
            spinnerStore.hideSpinner();

            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            await getBasic();
            return true;
        }

        return false;

    };
    

    function exist(id: number){
        return comparedBasic.value.includes(id) ? true : false;
    };
    
    const remove= async (id: number,seccion: seccion,marca: string,isComparatorPage: boolean)=>{
        if(exist(id)){
            const formData = new FormData();
            formData.append('id_vehiculos',id);
            formData.append('seccion',seccion);
            formData.append('marca',marca);
            spinnerStore.showSpinner();
            const { data,status, error } = await $api.comparator.remove(id.toString(),formData,{
                pick:['payload']
            });
            spinnerStore.hideSpinner();
            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }
            await getBasic();

            if(isComparatorPage){
                await getExtended();
            }

            return true;
        }

        return false;
    };


    const removeAll= async ()=>{
        if(!noCompared.value){

            const { data,status, error } = await $api.comparator.removeAll({
                pick:['payload']
            });

            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            comparedExtended.value=[];
            comparedBasic.value=[];
            info.value=[];

            return true;
        }

        return false;
    };


    /**
     * 
     * Con 1 traes los comparados version básica
     */
    const getBasic= async ()=>{
        if(isAuthenticated.value){
            const formData = new FormData();
            let attempt= false;

            formData.append('list_type','basic');


            spinnerStore.showSpinner();

            const { data,status,refresh, error } = await $api.comparator.index(formData,{
                pick:['payload'],                     
            });
                            
            if (((data.value === null) || ( !Array.isArray(data.value.payload) && typeof(data.value.payload)===Object )) && !attempt ) {
                attempt=true;
                await refresh();
            }

            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            comparedBasic.value=data.value.payload;
            spinnerStore.hideSpinner();
            return comparedBasic.value;
        }else{
            comparedExtended.value=[];
            comparedBasic.value=[];
            info.value=[];
        }

    };

    /**
     * 
     * traes la versión extendida
     */
    const getExtended= async ()=>{
        if(isAuthenticated.value){
            const formData = new FormData();
            let attempt= false;

            formData.append('list_type','extended');
            spinnerStore.showSpinner();

            const { data,status,refresh, error } = await $api.comparator.index(formData,{
                pick:['payload'],                     
            });
            
                
            if (((data.value === null) || ( Array.isArray(data.value.payload))) && !attempt ) {
                attempt=true;
                await refresh();
            }

            if (error.value) {
                throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message });
            }

            comparedExtended.value=data.value.payload.data;
            info.value=data.value.payload.metas;
            spinnerStore.hideSpinner();
            return comparedExtended.value;
        }else{
            comparedExtended.value=[];
            comparedBasic.value=[];
            info.value=[];
        }

    };



    watch(isAuthenticated, async ()=> {
        if (isAuthenticated.value) {
            await getBasic();
        }else{
            comparedExtended.value=[];
            comparedBasic.value=[];
            info.value=[];
        }
    });

    
    return {
        comparedBasic,
        comparedExtended,
        info,
        noCompared,
        add,
        remove,
        removeAll,
        getBasic,
        getExtended,
        exist
    };
});